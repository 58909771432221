const covidCancellationStr = "Cancelled due to COVID-19";
const events = [

    {
        dateText: "2023",
        description: "Saxophonist: The American Pops Orchestra. Luke Frazier, conductor.",
        city:  "Orlando FL",
        location: "Dr. Phillips Center",
    },
    {
        dateText: "2023",
        description: "Saxophonist: West Virginia Symphony Orchestra. Fouad Fakhouri, conductor.",
        city:  "Charleston, WV",
        location: "Clay Center",
    },
    {
        dateText: "2022",
        description: "Saxophonist: West Virginia Symphony Orchestra. Tom Consolo, conductor.",
        city:  "Charleston, WV",
        location: "Clay Center",
    },
    {
        dateText: "2022",
        description: "\“Music per le stella\” with the Estrella Consort.",
        city:  "Alba, IT",
        location: "Chiesa di San Giuseppe",
    },
    {
        dateText: "2022",
        description: "Chamber Concert with The Moanin’ Frogs.",
        city:  "Kelowna, BC",
        location: "Kelowna Community Concert Association",
    },
{
    dateText: "2023",
    description: "Faculty Showcase Recital: West Virginia University", city: "Morgantown, WV"
},
{
    dateText: "2023",
    description: "Guest Recital. California State University, Fullerton", city: "Fullerton, CA"
},
{
    dateText: "2023",
    description: "Guest Recital. University of Southern California", city: "Los Angeles, CA"
},
{
    dateText: "2023",
    description: "Guest Recital. California State University, Long Beach", city: "Los Angeles, CA"
},
{
    dateText: "2023",
    description: "Guest Recital. Eastern New Mexico University", city: "Portales, NM"
},
{
    dateText: "2023",
    description: "Guest Recital. Texas Tech University", city: "Lubbock, TX"
},
{
    dateText: "2023",
    description: "Guest Recital. University of Colorado, Boulder", city: "Boulder, CO"
},
{
    dateText: "2023",
    description: "Recital. North American Saxophone Alliance Biennial Conference. University of Southern Mississippi", city: "Hattiesburg, MS"
},
{
    dateText: "2023",
    description: "Guest Recital. Appalachian State University", city: "Boone, NC"
},
{
    dateText: "2023",
    description: "Guest Recital. University of North Carolina - Chapel Hill", city: "Chapel Hill, NC"
},
{
    dateText: "2023",
    description: "Guest Recital. University of Memphis", city: "Memphis, TN"
},
{
    dateText: "2023",
    description: "Guest Recital. Vanderbilt University", city: "Nashville, TN"
},
{
    dateText: "2023",
    description: "Guest Recital. Austin Peay State University", city: "Clarksville, TN"
},

{ dateText: "2020", description: "North American Saxophone Alliance Biennial Conference: Salvador Brotons – Sonata for Alto Saxophone and Piano", location: "Katzin Concert Hall", city: "Tempe, AZ" },
{ dateText: "2020", description: "Navy Band Symposium: The Music of Bedrossian and Pámies", location: "George Mason University", city: "Fairfax, VA" },
{ dateText: "2020", description: "Chamber Concert with The Moanin’ Frogs", location: "Kerrytown Concert House", city: "Ann Arbor, MI" },
{ dateText: "2020", description: "Chamber Concert and Presentation with The Moanin’ Frogs", location: "American Band College", city: "Online " },
{ dateText: "2020", description: "Chamber Concert with The Moanin’ Frogs", location: "The Conn Selmer Institute", city: "Mishawaka, IN.", notes: covidCancellationStr },
{ dateText: "2020", description: "Chamber Concert with The Moanin’ Frogs", location: "Trinity Church", city: "Watertown, NY", notes: covidCancellationStr },
{ dateText: "2020", description: "Chamber Concert with The Moanin’ Frogs", location: "University of North Carolina - Greensboro", city: "Greensboro, NC.", notes: covidCancellationStr },
{ dateText: "2020", description: "Chamber Concert with The Moanin’ Frogs", location: "The Oregon Music Educators Conference", city: "Eugene, OR" },
{ dateText: "2021", description: "Eric Domanico Memorial Concert with Branford Marsalis and Timothy McAllister", location: "First Congregational Church", city: "Ann Arbor, MI" },
{ dateText: "2021", description: "Artist Residency: Saxophone Day at the University of Arizona", city: "Tucson, AZ" },
{ dateText: "2021", description: "Featured Concertino Member (with The Moanin’ Frogs): American Band College Wind Ensemble, Robert Ponto, conductor", location: "SeaTac Hilton", city: "Seatac, WA" },
{ dateText: "2021", description: "Featured Soloist. West Virginia University Jazz Ensemble", city: "Morgantown, WV" },
{ dateText: "2021", description: "West Virginia University Jazz Faculty Concert. Antoinette Falbo Theatre", city: "Morgantown, WV" },
{ dateText: "2022", description: "Chamber Opera Performance: The William Blake Cycle: Unseen, Unbodied, Unknown", location: "The Cube", city: "Blacksburg, VA" },
{ dateText: "2022", description: "Chamber Concert with The Moanin’ Frogs", location: "Ventura Music Festival", city: "Ventura, CA", notes: covidCancellationStr },
{ dateText: "2022", description: "Chamber Concert with The Moanin’ Frogs", location: "The Muckenthaler Cultural Center", city: "Fullerton, CA", notes: covidCancellationStr },
{ dateText: "2022", description: "Chamber Concert with The Moanin’ Frogs", location: "Community Performance and Art Center", city: "Green Valley, AZ", notes: covidCancellationStr },
{ dateText: "2022", description: "Ensemble Residency with The Moanin’ Frogs", location: "Avaloch Farm Music Institute", city: "Boscawen, NH" },
{ dateText: "2022", description: "\“Sax and Friends\” with the Estrella Consort", location: "Sala Ghislieri", city: "Mondovi, IT" },
{ dateText: "2022", description: "Alba Music Festival Wet Inc Concert #2 with the Estrella Consort", location: "Chiesa della Maddalena", city: "Alba, IT" },
{ dateText: "2022", description: "Alba Music Festival Wet Inc Concert #1 with the Estrella Consort", location: "Chiesa della Maddalena", city: "Alba, IT" },
{ dateText: "2022", description: "“Music per le stella” with the Estrella Consort", location: "Chiesa di San Giuseppe", city: "Alba, IT" },
{ dateText: "2022", description: "Chamber Opera Performance: Hilma", location: "Rice University", city: "Houston, TX" },
{ dateText: "2022", description: "WVU Faculty Chamber Players", location: "Falbo Theater", city: "Morgantown, WV" },
{ dateText: "2022", description: "Chamber Concert with The Moanin’ Frogs", location: "Kelowna Community Concert Association", city: "Kelowna, BC" },
{ dateText: "2022", description: "Chamber Concert with The Moanin’ Frogs", location: "Trinity Church", city: "Watertown, NY" },
{ dateText: "2022", description: "Solo Recital", location: "Temple of Spiritual Truth", city: "Chicago, IL" },
{ dateText: "2022", description: "Guest Recital", location: "Virginia Polytechnic Institute and State University", city: "Blacksburg, VA" },
{ dateText: "2022", description: "North American Saxophone Alliance Biennial Conference: \“The Music of Flagello and Messiaen\”", location: "Oklahoma State University", city: "Stillwater, OK", notes: covidCancellationStr },
{ dateText: "2022", description: "Guest Recital", location: "Baldwin Wallace Conservatory of Music", city: "Berea, OH" },
{ dateText: "2022", description: "Guest Recital", location: "Mansfield University", city: "Mansfield, PA" },
{ dateText: "2022", description: "Navy Band Symposium: \“The Music of May and Burhans\”", location: "George Mason University", city: "Fairfax, VA", notes: covidCancellationStr },
{ dateText: "2022", description: "Saxophonist with Landau Eugene Murphy Jr.", location: "The Strand Theatre", city: "Moundsville, WV" },
{ dateText: "2022", description: "Saxophonist with Landau Eugene Murphy Jr.", location: "Cumberland County Playhouse", city: "Crossville, TN" },
{ dateText: "2022", description: "Saxophonist with Landau Eugene Murphy Jr.", location: "Hollywood Casino ", city: "Charles Town, WV" },
{ dateText: "2022", description: "West Virginia University Jazz Faculty Concert", location: "Clay Auditorium", city: "Morgantown, WV" },
{ dateText: "2022", description: "Tenor: Mendelssohn Choir and Pittsburgh Symphony Orchestra. Manfred Honeck, conductor", location: "Heinz Hall", city: "Pittsburgh, PA" },
{ dateText: "2022", description: "Tenor: Shadyside Presbyterian Chancel Choir. Mark Anderson, conductor", location: "Shadyside Presbyterian Church", city: "Pittsburgh, PA" },
{ dateText: "2022", description: "Tenor: Mendelssohn Choir and Pittsburgh Symphony Orchestra. Manfred Honeck, conductor", location: "Heinz Hall", city: "Pittsburgh, PA" },
{ dateText: "2022", description: "Tenor: Mendelssohn Choir and Pittsburgh Symphony Orchestra. Manfred Honeck, conductor", location: "Heinz Hall", city: "Pittsburgh, PA" },
{ dateText: "2022", description: "Tenor: Shadyside Presbyterian Chancel Choir. Mark Anderson, conductor", location: "Shadyside Presbyterian Church", city: "Pittsburgh, PA" },
{ dateText: "2022", description: "Yom Kippur Cantor", location: "Tree of Life Congregation", city: "Morgantown, WV" },
{ dateText: "2022", description: "Rosh Hashanah Cantor", location: "Tree of Life Congregation", city: "Morgantown, WV" },
{ dateText: "2022", description: "Tenor: Mendelssohn Choir and Pittsburgh Symphony Orchestra. Manfred Honeck, conductor", location: "Heinz Hall", city: "Pittsburgh, PA" },
{ dateText: "2022", description: "Tenor: Mendelssohn Choir and Pittsburgh Symphony Orchestra. Manfred Honeck, conductor", location: "Heinz Hall", city: "Pittsburgh, PA" },
{ dateText: "2023", description: "Tenor: Pittsburgh Camerata. Mark Anderson, conductor", location: "Shadyside Presbyterian Church", city: "Pittsburgh, PA" },
{ dateText: "2023", description: "Tenor: Mendelssohn Choir and Pittsburgh Symphony Orchestra. Manfred Honeck, conductor", location: "Heinz Hall", city: "Pittsburgh, PA" },
{ dateText: "2023", description: "West Virginia University Jazz Faculty Concert at Morgantown Wine and Jazz Festival", location: "Ruby Amphitheater", city: "Morgantown, WV" },
{ dateText: "2023", description: "West Virginia University Jazz Faculty Concert with David Liebman", location: "Clay Auditorium", city: "Morgantown, WV" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", city: "Redmond, OR" },
{ dateText: "2023", description: "Chamber Concert with The Estrella Consort", location: "Music in a Great Space Series at Shadyside Presbyterian Church", city: "Pittsburgh, PA" },
{ dateText: "2023", description: "The William Blake Cycle by Tiffany Skidmore", location: "The University of Buffalo", city: "Buffalo, NY" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", location: "Spring Valley Academy", city: "Centerville, OH" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", location: "Ventura Music Festival", city: "Ventura, CA" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", city: "Green Valley, AZ" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", city: "New London, NH" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", city: "Greensboro, VT" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", location: "Conn-Selmer Institute", city: "Mishawaka, IN" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", city: "Fredicksburg, TX" },
{ dateText: "2023", description: "Chamber Concert with The Moanin’ Frogs", location: "El Dorado Community Concert Series", city: "Placerville, CA" },
{ dateText: "2023", description: "WVU Faculty Chamber Players", location: "Davis Theater", city: "Morgantown, WV" },
  {
      dateText: "June 9, 2020",
      description: "The Moanin' Frogs Featured Artist at the Conn-Selmer Institute",
      city:  "Mishawaka, IN", location: "Bethel College", time:  "TBA",
  },
  {
      dateText: "April 19, 2020",
      description: "The Moanin' Frogs at the Trinity Concert Series",
      city:  "Watertown, NY", location: "Trinity Church", time:  "3:00 pm",
  },
  {
      dateText: "March 21, 2020",
      description: "The Moanin' Frogs Featured at the UNCG Sax Fest",
      city:  "Greensborough, NC", location: "UNCG Recital Hall", time:  "1:30 pm",
  },
  {
      dateText: "January 22, 2020",
      description: "Faculty Recital",
      city: "Morgantown, WV", location: "Bloch Hall", time:  "7:30 pm",
  },
  {
      dateText: "January 18, 2020",
      description: "The Moanin' Frogs Featured at Oregon Music Educators Association",
      city:  "Eugene, OR", location: "TBA", time:  "TBA",
  },
  {
      dateText: "November 9, 2019",
      description: "Orchestral Performance with the Ann Arbor Symphony Orchestra",
      city:  "Ann Arbor, MI", location: "Michigan Theater", time:  "8:00 pm",
  },
  {
      dateText: "November 5, 2019",
      description: "Faculty Showcase",
      city:  "Morgantown, WV", location: "Bloch Hall", time:  "7:30 pm",
  },
  {
      dateText: "November 3, 2019",
      description: "Concerto Performance with the Vancouver Symphony Orchestra",
      city:  "Vancouver, WA", location: "Skyview Concert Hall", time:  "7:00 pm",
  },
  {
      dateText: "November 2, 2019",
      description: "Concerto Performance with the Vancouver Symphony Orchestra",
      city:  "Vancouver, WA", location: "Skyview Concert Hall", time:  "3:00 pm",
  },
  {
      dateText: "October 26, 2019",
      description: "The Moanin' Frogs at Kerrytown Concert House",
      city:  "Ann Arbor, MI", location: "Kerrytown Concert House", time:  "2:00 pm",
  },
  {
      dateText: "October 25, 2019",
      description: "The Moanin' Frogs at Glacier Hills",
      city:  "Ann Arbor, MI", location: "Glacier Hills Senior Living Community", time:  "7:15 pm",
  },
  {
      dateText: "October 23, 2019",
      description: "The Moanin' Frogs in Three Lakes Wisconsin",
      city:  "Three Lakes, WI", location: "Three Lakes Center for the Arts", time:  "7:00 pm",
  },
  {
      dateText: "October 22, 2019",
      description: "The Moanin' Frogs in Spencer Wisconsin",
      city:  "Spencer, WI", location: "Lucille Tack Center for the Arts", time:  "7:00 pm",
  },
  {
      dateText: "October 20, 2019",
      description: "The Moanin' Frogs at Algoma Conservatory",
      city:  "Sault Ste. Marie, ON", location: "The Algoma Conservatory of Music", time:  "7:30 pm",
  },
  {
      dateText: "September 26, 2019",
      description: "The Moanin' Frogs at the UNR Performing Arts Series",
      city:  "Reno, NV", location: "Nightingale Concert Hall", time:  "7:30 pm",
  },
  {
      dateText: "September 11, 2019",
      description: "WVU Faculty Chamber Players",
      city:  "Athens, OH", location: "Glidden Hall", time:  "8:00 pm",
  },
  {
      dateText: "September 3, 2019",
      description: "WVU Faculty Chamber Players",
      city:  "Morgantown, WV", location: "Davis Theater", time:  "8:00 pm",
  },
  {
      dateText: "July 28, 2019",
      description: "Duo Concert with Hayley Boggs",
      city:  "Brooklyn, NY", location: "National Sawdust", time:  "7:00 pm",
  },
  {
      dateText: "June 9, 2019",
      description: "SWE 50",
      city:  "Evanston, IL", location: "Pick-Staiger Concert Hall", time:  "7:30 pm",
  },
  {
      dateText: "May 20-June 3, 2019",
      finalDateText: "June 3, 2019",
      description: "Orchestral Residency with the Spoleto Festival USA Orchestra",
      city:  "Charleston, SC", location: "Gaillard Center",
  },
  {
      dateText: "March 31, 2019",
      description: "Chamber Concert: \"The Quietest of Whispers\" with ÆPEX Contemporary",
      city:  "Mount Pleasant, MI", location: "Staples Family Concert Hall", time:  "7:30 pm",
  },
  {
      dateText: "March 30, 2019",
      description: "Lecture: \"Grit in and through Contemporary Saxophone Repertoire\"",
      city:  "Mount Pleasant, MI", location: "Central Michigan University", time:  "Time and Hall TBA",
  },
  {
      dateText: "March 30-31, 2019",
      finalDateText: "March 31, 2019",
      description: "Residency at CMU with ÆPEX Contemporary Performance",
      city:  "Mount Pleasant, MI", location: "Central Michigan University",
  },
  {
      dateText: "March 26, 2019",
      description: "Doctoral Recital #3: The New Virtuosity",
      city:  "Ann Arbor, MI", location: "McIntosh Theatre", time:  "8:00 pm",
  },
  {
      dateText: "March 25, 2019",
      description: "Ann Arbor Residency for Jewish Art Music with Jascha Nemtsov",
      city:  "Ann Arbor, MI", location: "Britton Hall", time:  "8:00 pm",
  },
  {
      dateText: "March 10, 2019",
      description: "Masterclass and Clinic with The Moanin' Frogs",
      city:  "Tucson, AZ", location: "The University of Arizona", time:  "10:00 am",
  },
  {
      dateText: "March 9, 2019",
      description: "Chamber Concert with The Moanin' Frogs",
      city:  "Tucson, AZ", location: "The University of Arizona", time:  "2:00 pm",
  },
  {
      dateText: "March 1, 2019",
      description: "Doctoral Recital #2: A Cold Threshold",
      city:  "Ann Arbor, MI", location: "The Duderstadt Video Studio", time:  "8:00 pm",
  },
  {
      dateText: "December 16, 2018",
      description: "Chamber Concert with New World Symphony Players",
      city:  "Miami Beach, FL", location: "The New World Center", time:  "2:00 pm (REVIEW!)",
  },
  {
      dateText: "November 18, 2018",
      description: "Doctoral Recital #1: Yizkor, In Memoriam - On Memory and Mourning",
      city:  "Ann Arbor, MI", location: "McIntosh Theatre", time:  "4:00 pm",
  },
  {
      dateText: "October 6, 2018",
      description: "Orchestral Performance with the New World Symphony",
      city:  "Miami Beach, FL", location: "The New World Center", time:  "8:00 pm",
  },
  {
      dateText: "September 15, 2018",
      description: "Orchestral Performance with the Ann Arbor Symphony Orchestra",
      city:  "Ann Arbor, MI", location: "Hill Auditorium", time:  "8:00 pm",
  },
  {
      dateText: "August 23, 2018",
      description: "Go Blue: Wolverine New Music",
      city:  "Brooklyn, NY", location: "National Sawdust", time:  "8:00 pm (REVIEW!)",
  },
  {
      dateText: "June 23, 2018",
      description: "Orchestral Performance with the Artosphere Festival Orchestra",
      city:  "Fayetteville, AR", location: "The Walton Center for the Performing Arts", time:  "8:00 pm",
  },
  {
      dateText: "March 20, 2018",
      description: "Residency with the Fonema Consort",
      city:  "Berkeley, CA", location: "UC Berkeley", time:  "All Day",
  },
  {
      dateText: "March 20, 2018",
      description: "Chamber Concert with the Nina Dante, Voice",
      city:  "Berkeley, CA", location: "CNMAT", time:  "8:00 pm",
  },
  {
      dateText: "March 14, 2018",
      description: "Exercising the Ear: Music and Conversation with Augusta Read Thomas",
      city:  "Ann Arbor, MI", location: "University of Michigan Museum of Art", time:  "5:00 pm",
  },
  {
      dateText: "February 16, 2018",
      description: "World Premiere of “Six Ways to Dance” by Karalyn Schubring",
      city:  "Ann Arbor, MI", location: "Hankinson Hall", time:  "8:00 pm",
  },
  {
      dateText: "December 12, 2017",
      description: "Orchestral Performance with the Saginaw Bay Symphony Orchestra",
      city:  "Saginaw, MI", location: "Temple Theater", time:  "8:00 pm",
  },
  {
      dateText: "December 9, 2017",
      description: "Orchestral Performance with the New World Symphony",
      city:  "Miami Beach, FL", location: "The New World Center", time:  "8:00 pm",
  },
  {
      dateText: "November 7, 2017",
      description: "Orchestral Performance with the Ann Arbor Symphony Orchestra",
      city:  "Ann Arbor, MI", location: "The Michigan Theater", time:  "8:00 pm",
  },
  {
      dateText: "October 21, 2017",
      description: "Orchestral Performance with the Ann Arbor Symphony Orchestra",
      city:  "Ann Arbor, MI", location: "Hill Auditorium", time:  "8:00 pm",
  },
  {
      dateText: "October 14, 2017",
      description: "Orchestral Performance with the New World Symphony",
      city:  "Miami Beach, FL", location: "The New World Center", time:  "8:00 pm",
  },
  {
      dateText: "September 16, 2017",
      description: "Orchestral Performance with the Ann Arbor Symphony Orchestra",
      city:  "Ann Arbor, MI", location: "The Michigan Theater", time:  "8:00 pm",
  },
  {
      dateText: "May 20, 2017",
      description: "Orchestral Performance with the Artosphere Festival Orchestra",
      city:  "Fayetteville, AR", location: "The Walton Center for the Performing Arts", time:  "8:00 pm",
  },
  {
      dateText: "April 22, 2017",
      description: "Solo Recital at the Society for Electro-Acoustic Music in the US",
      city:  "Saint Cloud, MN", location: "Saint Cloud State University", time:  "3:00 pm",
  },
  {
      dateText: "March 17, 2017",
      description: "Chamber Concert with the Estrella Consort",
      city:  "Cincinnati, OH", location: "CCM", time:  "2:30 pm",
  },
  {
      dateText: "March 3, 2017",
      description: "Chamber Concert with Ann Arbor Symphony Players",
      city:  "Ann Arbor, MI", location: "Jewish Community Center of Greater Ann Arbor", time:  "1:30 pm",
  },
  {
      dateText: "December 4, 2016",
      description: "Chamber Concert with New World Symphony Players",
      city:  "Miami, FL", location: "The New World Center", time:  "7:30 pm",
  },
  {
      dateText: "November 4-5, 2016",
      finalDateText: "November 5, 2016",
      description: "Residency at the Musical Instrument Museum",
      city:  "Phoenix, AZ", location: "The Musical Instrument Museum", time:  "10:00 am, 12:00 pm",
  },
  {
      dateText: "October 8, 2016",
      description: "Concert with the Ann Arbor Symphony Orchestra",
      city:  "Ann Arbor, MI", location: "Michigan Theater", time:  "8:00 pm",
  },
  {
      dateText: "September 17, 2016",
      description: "Duo Recital with Michael Refvem",
      city:  "Chicago, IL", location: "Pianoforte Foundation", time:  "4:00 pm",
  },
  {
      dateText: "August 28, 2016",
      description: "Chamber Concert with the Fonema Consort",
      city:  "Chicago, IL", location: "Constellation", time:  "8:30 pm",
  },
  {
      dateText: "July 14, 2016",
      description: "Solo Recital Representing the Fonema Consort",
      city:  "Chicago, IL", location: "Comfort Station", time:  "7:30 pm",
  },
  {
      dateText: "June 6, 2016",
      description: "New York Philharmonic Biennial - NYC Electroacoustic Festival",
      city:  "New York, NY", location: "National Sawdust", time: "8:00 pm",
  },
  {
      dateText: "May 23-27, 2016",
      finalDateText: "May 27, 2016",
      description: "Fellowship - YIVO Seminar in Archival and Historical Research",
      city:  "New York, NY", location: "YIVO Institute for Jewish Research", time:  "8:00 am - 5:00 pm",
  },
  {
      dateText: "May 20, 2016",
      description: "Featured Soloist with the Northwestern University CME",
      city:  "Evanston, IL", location: "Ryan Center for the Performing Arts", time:  "7:30 pm",
  },
  {
      dateText: "May 17, 2016",
      description: "Clarinetist on Joan Arnau Pàmies's DMA Recital",
      city:  "Evanston, IL", location: "Ryan Center for the Performing Arts", time:  "7:30 pm",
  },
  {
      dateText: "April 15, 2016",
      description: "Duo Recital with Michael Refvem",
      city:  "Corvallis, OR", location: "Oregon State University", time:  "12:30 pm",
  },
  {
      dateText: "April 11, 2016",
      description: "Residency with the Fonema Consort",
      city:  "Chicago, IL", location: "Chicago Academy for the Arts", time:  "3:00 pm",
  },
  {
      dateText: "April 29, 2016",
      description: "Concert with the Gabriel Construct",
      city:  "Chicago, IL", location: "Elbo Room", time:  "8:00 pm",
  },
  {
      dateText: "January 27, 2016",
      description: "Chamber Recital with casalQuartett",
      city:  "Arosa, Switzerland", location: "Evangischekirche", time:  "6:00 pm",
  },
  {
      dateText: "January 22, 2016",
      description: "Duo Recital with Angela Draghicescu",
      city:  "Blonay, Switzerland", location: "Hindemith Institute", time:  "5:00 pm",
  },
  {
      dateText: "January 19, 2016",
      description: "Duo Recital with Angela Draghicescu",
      city:  "Budapest, Hungary", location: "Romanian Cultural  Center", time:  "7:00 pm",
  },
  {
      dateText: "January 16, 2016",
      description: "Duo Recital with Florian von Radowitz",
      city:  "Winterthur, Switzerland", location: "Villa Sträuli", time:  "1:00 pm",
  },
  {
      dateText: "December 16, 2015",
      description: "Lecture Recital: Dialectical Development in Edison Denisov's Sonata",
      city:  "Lubbock, TX", location: "Texas Tech University", time:  "10:00 am",
  },
  {
      dateText: "December 6, 2015",
      description: "Concert with the New World Symphony",
      city:  "Miami Beach, FL", location: "New World Center", time:  "7:00 pm",
  },
  {
      dateText: "November 15, 2015",
      description: "Residency with the Estrella Consort",
      city:  "Knoxville, TN", location: "University of Tennessee, Knoxvile", time:  "2:00 pm",
  },
  {
      dateText: "November 6, 2015",
      description: "Lecture Recital: Embodied Labor in Franck Bedrossian's Work",
      city:  "Evanston, IL", location: "Northwestern University", time:  "4:00 pm",
  },
  {
      dateText: "November 1, 2015",
      description: "Chamber Recital with the Fonema Consort",
      city:  "Chicago, IL", location: "Constellation", time:  "10:00 pm",
  },
  {
      dateText: "September 8, 2015",
      description: "Lecture Recital: Embodied Labor in Franck Bedrossian's Work",
      city:  "Glasgow, UK", location: "The University of Glasgow", time:  "1:00 pm",
  },
  {
      dateText: "September 7, 2015",
      description: "Chamber Recital with George Enescu Philharmonic Players",
      city:  "Bucharest, Romania", location: "George Enescu National Concert Hall", time:  "7:00 pm",
  },
  {
      dateText: "September 5, 2015",
      description: "Chamber Recital with George Enescu Philharmonic Players",
      city:  "Bucharest, Romania", location: "Sutzu Palace Concert Hall", time:  "7:00 pm",
  },
  {
      dateText: "September 3, 2015",
      description: "Duo Recital with Angela Draghicescu",
      city:  "Budapest, Hungary", location: "Bartók Hall - Hungarian Institute of Musicology", time:  "7:30 pm",
  }
]
export default events;