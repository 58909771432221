import events from "./eventData.js";

const pastEvents = events
.filter(event => new Date(event.finalDateText ?? event.dateText) < new Date())
.sort((event1, event2) => new Date(event2.finalDateText ?? event2.dateText) - new Date(event1.finalDateText ?? event1.dateText));
const upcomingEvents = events
.filter(event => new Date(event.finalDateText ?? event.dateText) >= new Date())
.sort((event1, event2) => new Date(event2.finalDateText ?? event2.dateText) - new Date(event1.finalDateText ?? event1.dateText));

function getEventElement(event) { 
const eventTime = event.hasOwnProperty("time") ? " || " + event.time  : "";
const eventLocation = event.hasOwnProperty("location") ? " || " + event.location  : "";
// const moreInformation = event.hasOwnProperty("forMoreInformation") ?
//     <><div className="eventInfo">for more information: <a href={event.forMoreInformation.url}>{event.forMoreInformation.text}</a></div></>
//     : <></>;
return <>
  <div className="event">
  <div className="date">{ event.dateText }</div>
  <div>{ event.description }</div>
  <div>{ event.city + eventLocation + eventTime}</div>
  </div>
</>
}

const pastEventsContent = pastEvents.map(event => getEventElement(event));
const upcomingEventsContent = upcomingEvents.map(event => getEventElement(event));
export default () => <><h3>Upcoming Events</h3>{ upcomingEventsContent }<h3>Past Events</h3>{ pastEventsContent }</>;
