

  // BIO PAGE
const officialBio = <><p>Saxophonist Jeff Siegfried combines a “rich, vibrant tone” (South Florida Classical Review) with “beautiful and delicate playing” (Michael Tilson Thomas) to deliver “showstopper performances” (Peninsula Reviews). Hailed for his “quicksilver” interpretations (I Care if You Listen), Siegfried has become an important voice in his generation of concert saxophonists.</p>
<p>Siegfried has been honored at numerous international competitions. He has received first prize at the Luminarts Fellowship Competition and the Frances Walton Competition and was runner up in the Carmel Music Society Competition, the North American Saxophone Alliance Quartet Competition, and the Music Teachers National Association Chamber Music Competition. He is the recipient of the 2016 Hans Schaeuble Award.</p>
<p>Siegfried has appeared as a featured soloist with the U.S. Army Band “Pershing’s Own,” the University of Portland Wind Ensemble, the Oregon State University Wind Ensemble, and the Northwestern University Contemporary Music Ensemble. He has also appeared as an orchestral saxophonist with the Chicago Symphony Orchestra, the New World Symphony, the Schleswig-Holstein Musik Festival, and Spoleto Festival, USA. His recording credits appear on Parma, Mark Records, and Parlour Tapes.</p>
<p>As a champion of new music, Siegfried has commissioned many works for the saxophone. He has collaborated with numerous composers, including Matthew Browne, Nicholas Cline, Joey Crane, Gala Flagello, Sean Friar, Elliott McKinley, Clara Olivares, Joan Arnau Pàmies, Robert Reinhart, David Reminick, Karalyn Schubring, Gregory Wanamaker, Eric Wubbels, Katherine Young, and Daniel Zlatkin.</p>
<p>Siegfried is also an active scholar in the field of musicology. His scholarly interests included 20th century Jewish art music, Yiddishkayt, music and politics, performance studies, and the critical organology of the saxophone. He has presented his research at numerous international conferences from Brussels to Budapest.</p>
<p>Siegfried has collaborated with chamber ensembles from around the world. His saxophone sextet, The Moanin’ Frogs, has received considerable international acclaim and competitive success. His saxophone quartet, the Estrella Consort, has performed for diverse audiences from Ecuador to Tennessee. He has appeared with other award-winning ensembles, including casalQuartett and the Fonema Consort.</p>
<p>Siegfried holds masters degrees in saxophone and in musicology from Northwestern University and a doctorate in saxophone with certificates in musicology and in performance studies from the University of Michigan. His principal teachers are Timothy McAllister and Andrew Bishop. He is a Selmer Artist-Clinician and plays Selmer saxophones and mouthpieces exclusively. Siegfried serves as Assistant Professor of Saxophone at West Virginia University.</p></>

export default () => <>
    <img src='../media/jeffsFace.jpg' id="jeffsFace"/>
    <div className="bioParagraph">{ officialBio }</div>
</>;
