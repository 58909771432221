import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import {isMobile} from "react-device-detect";
import Discography from "./pages/Discography";
import Contact from "./pages/Contact";
import Bio from "./pages/Bio";
import Events from "./pages/Events";
import Media from "./pages/Media";
import Press from "./pages/Press";
import Publications from "./pages/Publications";
import Nav from "./Nav";

function App() {
  const homeContent = <></>;

  const getIsMobile = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const mobile = isMobile || vw < vh;
    return mobile;
  }
  const [mobile, setMobile] = useState(getIsMobile());

  const getNavBarHeight = () => { return document.getElementById("mobile-navigation-menu")?.getBoundingClientRect().height; };
  window.onresize = function() {
    const mobile = getIsMobile();
    setMobile(mobile);
    const navBarHeight = mobile ? getNavBarHeight() : 0;
    document.getElementById("app-content").style.paddingTop = ""  + navBarHeight + "px";
    document.getElementById("background").style.backgroundPositionY = ""  + (navBarHeight - (window.innerWidth / 7.)) + "px";
    var homePageBackgroundElements = document.getElementsByClassName("homePageBackground");
    if (homePageBackgroundElements.length) {
      if (window.innerWidth < (window.innerHeight * 4. / 6.)) {
        homePageBackgroundElements[0].style.backgroundPositionX = "" + ((window.innerWidth - (window.innerHeight * 4. / 6.)) * 0.75) + "px";
      } else {
        homePageBackgroundElements[0].style.backgroundPositionX = "0px";
      }
    }
  }
  
  window.onload = window.onresize;
  useEffect(window.onresize);
  const [isOpen, setOpen] = useState(false)

        
  return <div className={ mobile ? "App mobile" : "App" }>
      <div className={ window.location.pathname==="/" ? "background homePageBackground" : "background contentPageBackground"} id="background"></div>
      <div className="app-content" id="app-content">
        <Nav mobile={mobile} isOpen={isOpen} setOpen={setOpen}/>
        <div className="main-section">
          { mobile ? <></> : <div className="pageHeader"><div className="headerContent">Jeff Siegfried |<div className="headerSubcontent">| Saxophonist</div></div></div> }
          <div>
            <BrowserRouter>
              <Routes>
                  <Route index element={homeContent} />
                  <Route path="/bio" element={<Bio/>} />
                  <Route path="/events" element={<Events/>} />
                  <Route path="/discog" element={<Discography/>} />
                  <Route path="/media" element={<Media/>} />
                  <Route path="/press" element={<Press/>} />
                  <Route path="/publications" element={<Publications/>} />
                  <Route path="/contact" element={<Contact/>} />
              </Routes>
            </BrowserRouter>
          </div>
        </div>
      </div>
    </div>;
}

export default App;
