import React from "react";
import Hamburger from "hamburger-react";


function Nav({mobile, isOpen, setOpen }) {
    const navigationButtons = [
        { label: "Home", href: "" },
        { label: "Biography", href: "bio" },
        { label: "Events", href: "events" },
        { label: "Discography", href: "discog"},
        { label: "Media", href: "media" },
        { label: "Press Materials", href: "press" },
        { label: "WVU School of Music", externalLink: "https://www.music.wvu.edu/" },
        { label: "The Moanin' Frogs", externalLink: "https://www.themoaninfrogs.com/" },
        { label: "Publications", href: "publications" },
        { label: "Contact", href: "contact" },
    ];
    
    const handleClick = (button) => {
        if (button.hasOwnProperty("href")) {
            let href = window.location.protocol + "//" + window.location.host + "/" + button.href;
            window.location.assign(href);
        }
        if (button.hasOwnProperty("externalLink")) {
            window.open(button.externalLink, "_blank");
        }
    };
 
    // const [isOpen, setOpen] = useState(false)
    return mobile
        ? <div className="mobile-navigation-menu" id="mobile-navigation-menu">
            { isOpen ? <div className="headerContent">{navigationButtons.map((button, index) => 
                <button className="navigationButton mobile" key={index} onClick={() => handleClick(button)}>{button.label}</button>)}</div>
                : <div className="headerContent">Jeff Siegfried |<div className="headerSubcontent">| Saxophonist</div></div>
            }
            <Hamburger toggled={isOpen} toggle={setOpen} color="#FFFFFF" onClick={window.onresize}/>
            </div>
        : <span className="navigation-menu">{navigationButtons.map((button, index) => 
            <button className="navigationButton desktop" key={index} onClick={() => handleClick(button)}>{button.label}</button>)}
            </span>
}

export default Nav;
    