
const discography = [
    {
        title: "Zae Munn: They Were Mysterious Guests",
        pieces: [
            { title: "Broken Tulip", movements: [], highlighted: false },
            { title: "They Were Mysterious Guests", 
                movements: 
                [
                    "I. Hard to Capture",
                    "II. If Enough Hosts Weep...",
                    "III. Recollection",
                ], highlighted: false
            },
            { title: "Disclosure", movements: [], highlighted: false },
            { title: "The Old Songs", movements: [], highlighted: false },
            { title: "Cascade", movements: [], highlighted: false },
            { title: "Music: A Love Story", movements: [], highlighted: false },
            { title: "Hanging onto the Vine", movements: [], highlighted: true },
        ],
        coverArt: "discogImages/zaeMunnMysteriousGuests.jpg"
    },
    {
        title: "Currents",
        pieces: [
            { title: "Currents", movements: [], highlighted: false },
            { title: "Upriver", movements: [], highlighted: false },
            { title: "Double Concertino for Tenor Sax...", 
                movements: ["I. Praeludium  - Motus perpetuus", "II. Melos", "III. Pugha"],
                highlighted: true },
            { title: "The Vistas of America", 
                movements: ["I. Pacific Ocean", "II. The Mountains",
                    "III. The Plains", "IV. Cities", "V. Atlantic Ocean"],
                highlighted: false },
        ],
        coverArt: "discogImages/osuCurrents.jpg"
    },
];

export default discography;