// import React, { Component } from "react";
import discography from "./discogData.js";

function getDiscElement(disc) {
  function getTrackList(pieces) {
    function getPiece(piece) {
      function getMovements(movements) {
        function getMovement(movement) {
          return <><div className="movement">{ movement }</div></>
        }
        return movements.map(movement => getMovement(movement));
      }
      return <>
        <div className={ piece.highlighted ? "highlighted" : "" }>
          <div className="piece">{ piece.title }</div>
          { getMovements(piece.movements) }
        </div>
      </>
    }
    return pieces.map(piece => getPiece(piece));
  }
  return <>
    <div className="discogTitle">{ disc.title }</div>
    <div style={{overflow:"hidden",marginBottom:"20px"}}>
      <span className="discogContainer trackList">{ getTrackList(disc.pieces) }</span>
      <span className="discogContainer coverArt"><img className="discogImage" src={ disc.coverArt }></img></span>
    </div>
  </>
}

// class Discography extends Component {
//     render() {
//       return discography.map(disc => getDiscElement(disc));
//     }
// }
export default () => discography.map(disc => getDiscElement(disc));

