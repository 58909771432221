const photoInds = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const photoIndStrings = photoInds.map(i => i < 10 ? "0" + i : "" + i);
const photoElements = <><div className="smallPressPhotoContainer">
{
  photoIndStrings.map(indString => {
    const directory = "media/photos/modified/";
    const smallFilename = indString + "small.jpg";
    const largeFilename = indString + "large.png";
    return <a href={directory + largeFilename}><img className="smallPressPhoto" src={directory + smallFilename} /></a>;
  })
}</div></>

export default () => <><div><h3>Press Photos</h3>{ photoElements }</div></>;