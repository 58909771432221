// HOME PAGE / MEDIA
const youTubeURLs = [
    "https://www.youtube.com/embed/Nbbl_IeuTAk?si=2ToirPDU02AbrRia",
    "https://www.youtube.com/embed/NuDPR7p6wws?si=Bv5Yq6eG8nJLi7XE",
    "https://www.youtube.com/embed/WGozn_5b3Ao?si=zOJAbR0ZwveksaOZ",
    "https://www.youtube.com/embed/kbbOgtswWJI?si=YWQtXK-Prcmfzb3z",
    "https://www.youtube.com/embed/_c5lfRRgipc?si=Dqb87KE5Sh57T3gp",
    "https://www.youtube.com/embed/dv2nDeWXFno?si=2cmkRo9wLh5NmGXB",
];
const youTubes = youTubeURLs.map(url => <iframe className="youtube" src={url} title="Sun Chang Plays" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>);
const soundCloud = [
// <iframe width="100%" height="150" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/232027873&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
];
const mp3s = [
// <audio controls="controls" id="brahms_player">
//   <source src="media/brahms2.mp3" type="audio/mpeg" />
//   Your browser does not support the audio element.
// </audio>
];

const streams = youTubes.concat(soundCloud).concat(mp3s);

export default () => streams.map(stream => <div className="stream">{stream}</div>);
